import store from '../store'
import dashRoutes from './dashboard.routes'

export default [
  {
    path: '',
    redirect: () => {
      return { name: 'dashboard' }
    },
  },
  {
    path: 'dashboard',
    component: {
      render(h) {
        return h('router-view')
      },
    },
    children: dashRoutes,
  },
  {
    path: 'account',
    name: 'admin-account',
    component: () =>
      import(
        /* webpackChunkName: "view-admin-account" */ '@/views/admin/AdminAccount.vue'
      ),
    beforeEnter: (to, from, next) => {
      if (store.state.auth.user.roleName !== 'Admin') {
        return next('/')
      } else {
        return next()
      }
    },
  },
  {
    path: 'users',
    name: 'admin-user-management',
    component: () =>
      import(
        /* webpackChunkName: "view-manageusers" */ '@/views/admin/AdminManageUsers.vue'
      ),
  },
  {
    path: 'roles',
    name: 'admin-permission-rules',
    component: () =>
      import(
        /* webpackChunkName: "view-roles" */ '@/views/admin/AdminRoles.vue'
      ),
  },
  {
    path: 'business-rules',
    component: {
      render(h) {
        return h('router-view')
      },
    },
    children: [
      {
        path: '',
        name: 'admin-business-rules-home',
        component: () =>
          import(
            /* webpackChunkName: "view-settings-home" */ '@/views/admin/AdminBusinessRules.vue'
          ),
      },
      {
        path: 'iframe',
        name: 'admin-business-rules-home-iframe',
        component: () =>
          import(
            /* webpackChunkName: "view-settings-home" */ '@/views/admin/AdminBusinessRulesIframe.vue'
          ),
      },
      {
        path: 'layout',
        name: 'admin-layout',
        component: () =>
          import(
            /* webpackChunkName: "view-cleandata-admin-presentation-rules-list" */ '@/views/admin/AdminPresentationRulesList.vue'
          ),
        meta: {
          breadcrumb: [
            {
              text: 'Business Rules',
              to: { name: 'admin-business-rules-home' },
              exact: true,
              disabled: false,
            },
            { text: 'Layout', disabled: true },
          ],
        },
      },
      {
        path: 'detect-and-merge',
        component: {
          render(h) {
            return h('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'admin-detect-and-merge',
            component: () =>
              import(
                /* webpackChunkName: "view-settings-home" */ '@/views/admin/AdminBusinessRulesDetectAndMerge.vue'
              ),
            meta: {
              breadcrumb: [
                {
                  text: 'Detect & Merge',
                  to: { name: 'admin-business-rules-home' },
                  exact: true,
                  disabled: false,
                },
                { text: 'Business Rules', disabled: true },
              ],
            },
          },
          {
            path: ':directorRuleId/:action',
            name: 'admin-manage-business-rule',
            component: () =>
              import(
                /* webpackChunkName: "view-managebusinessrule" */ '@/views/admin/AdminBusinessRulesDetectAndMergeDetails.vue'
              ),
            meta: {
              isSideBarHidden: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'search-before-create',
    component: {
      render(h) {
        return h('router-view')
      },
    },
    children: [
      {
        path: '',
        name: 'admin-search-before-create-home',
        component: () =>
          import(
            /* webpackChunkName: "view-settings-home" */ '@/views/admin/AdminSearchBeforeCreate.vue'
          ),
      },
      {
        path: 'layouts',
        name: 'admin-search-before-create-layout',
        component: () =>
          import(
            /* webpackChunkName: "view-cleandata-admin-presentation-rules-list" */ '@/views/admin/AdminSearchBeforeCreatePresentationRulesIframe.vue'
          ),
        meta: {
          isFullPage: true,
        },
      },
      {
        path: 'business-rules',
        component: {
          render(h) {
            return h('router-view')
          },
        },
        children: [
          {
            path: '',
            name: 'admin-search-before-create-business-rules',
            component: () =>
              import(
                /* webpackChunkName: "view-settings-home" */ '@/views/admin/AdminSearchBeforeCreateBusinessRulesIframe.vue'
              ),
            meta: {
              isFullPage: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: 'general-settings',
    name: 'admin-general-settings-home',
    component: () =>
      import(
        /* webpackChunkName: "view-AdminGeneralSettingsSetup" */ '@/views/admin/AdminGeneralSettingsSetupIframe.vue'
      ),
    meta: {
      isFullPage: true,
      isSetup: true,
    },
  },
]
